import "./src/styles/index.scss";

import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
export const wrapRootElement = ({ element }: *): * => (
    <ParallaxProvider>{element}</ParallaxProvider>
);
/*const scrollToId = (id) => () => {
    const el = document.querySelector(id);
    if (el) {
        var viewportOffset = el.getBoundingClientRect();
        // these are relative to the viewport, i.e. the window
        var top = viewportOffset.top;
        return window.scrollTo(0, top);
    }
    return false;
};

export const onRouteUpdate = ({ location: { hash } }) => {
    if (hash) {
        window.setTimeout(scrollToId(hash), 1200);
    }
};
*/

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (location && location.state) {
        location.state.referrer = prevLocation ? prevLocation.pathname : null;
    }
    if (!prevLocation && !location.hash) {
        setTimeout(
            () => window.scrollTo({ top: 0, left: 0, behavior: "instant" }),
            1,
        );
    }
};

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    window.history.scrollRestoration = "manual";
    const currentPosition = getSavedScrollPosition(location) || [0, 0];

    setTimeout(() => {
        if (location.hash) {
            // If hash (`/#something`), smooth scroll to that position
            const item = document.querySelector(`${location.hash}`);
            item?.scrollIntoView({ behavior: "smooth" });
        } else {
            // when using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
            window.scrollTo({
                top: currentPosition[1],
                left: currentPosition[0],
                behavior: "instant",
            });
        }
    }, 1);

    // do not let gatsby do anything more
    return false;
};
